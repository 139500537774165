<template>
  <module
    ref="module"
    id="whitelabelCrud"
    titleIcon="fa fa-newspaper-o"
    :title="$t('whitelabelmanagepanel')"
    :filters.sync="filters"
    api-url="WLADMINAPIURL"
    url="/whitelabel/search/"
    :useDefaultCrud="true"
    @on-save="validateAndSave"
    @on-select="fillName"
    @on-del="deleteWhitelabel"
    @on-edt="editWhitelabel"
  >
    <template slot="table-columns">
      <el-table-column prop="name" :label="$t('name')" sortable="custom"></el-table-column>
    </template>
    <template slot="edt">
      <whitelabel-form
        ref="form">
      </whitelabel-form>
    </template>

  </module>
</template>

<script>

import WhitelabelForm from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/WhitelabelForm.vue";

export default {
  name: "whitelabel-crud",
  components: {WhitelabelForm},
  data() {
    return {
      filters: {}
    }
  },
  methods: {
    validateAndSave() {
      let fail = resp => {
        if (resp.data !== undefined) {
          resp = resp.data.msg;
        }
        this.$toast.error(this.$t(resp));
      }
      let success = resp => {
        this.$refs.module.refreshList()
      }
      this.$refs.form.submit().then(success, fail)
    },
    fillName: function (the_event, row) {
      if (row) {
        this.selected_name = row.name
      }
    },
    deleteWhitelabel() {
      if (this.selected_name != '') {
        this.$deleteWhitelabel(this.selected_name).then(
          () => this.$refs.module.refreshList()
        )
      }
    },
    editWhitelabel: function (row) {
      if (row) {
        this.isEditing = true
        this.getWhitelabel(row)
      }
    },
    async getWhitelabel(row) {
      let success = resp => {
        this.$refs.form.editForm(resp)
      }
      let fail = resp => {
        this.$toast.warning("deu ruim")
      }
      this.$getWlByName(row.name).then(success, fail)
    }


  }
}
</script>

<style scoped>

</style>
