<template>
  <div class="row col-10">
    <div v-for="item in txtContent">
      <pre v-if="item.type == wlPlanType">
        <p><i :class="item.icon" style="padding-right: 5px"></i><b>{{ item.title }}:</b>{{ item.content }}</p>
    </pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "Whitelabel-info",
  props: {
    wlPlanType: String,
    description: 'type of plan, reference to type description'
  },
  data() {
    return {
      txtContent: [
        {
          type: "affiliate",
          icon: 'fa fa-users',
          title: "Affiliate",
          content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
        },
        {
          type: "broker",
          icon: "fa fa-building",
          title: "Broker",
          content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
        },
        {
          type: "trader",
          icon: "fa fa-line-chart",
          title: "Trader",
          content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book"
        }
      ]
    }
  }
}
</script>

<style scoped>
pre {
  color: inherit;
  white-space: pre-wrap;
  font-family: 'sans-serif' !important;
  font-size: medium;
  padding: 25px;
  border: dashed 1px rgba(102, 97, 91, 0.3);
}

i {
  padding-right: 5px;
}

b {
  padding-right: 5px;
}
</style>
