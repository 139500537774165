<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <h5>{{ $t('affiliatetxt') }}</h5>
    </div>
    <div class="col-md-6 mt-5">
      <div class="mt-2 mb-3">
        <p-switch :value="affiliate_activate"><i class="fa fa-check" slot="on"></i></p-switch>
        Ativar Afiliação
      </div>
      <div class="mt-2 mb-3">
        <p-switch v-model="affiliate_payment_hoko"><i class="fa fa-check" slot="on"></i></p-switch>
        Usar Pagamentos HOKO
      </div>
      <div class="mt-3 mb-2">
        <p-switch v-model="affiliate_strategy_marketplace"><i class="fa fa-check" slot="on"></i></p-switch>
        Seletor de Estrategis do Marketplace
      </div>
    </div>
    <div class="col-md-6 mt-5">

    </div>

  </div>
</template>

<script>
import PSwitch from "@/components/UIComponents/Switch-2.0.0.vue";
import WhitelabelInfo from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-info.vue";

export default {
  name: "Whitelabel-affiliate",
  components: {WhitelabelInfo, PSwitch},
  props: {
    affiliate_activate: {
      type: Boolean,
      description: '',
      default: false
    },
    affiliate_payment_hoko: {
      type: Boolean,
      description: '',
      default: false
    },
    affiliate_strategy_marketplace: {
      type: Boolean,
      description: '',
      default: false
    },
  }
}
</script>

<style scoped>

</style>
