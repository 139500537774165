<template>
  <div>
    <div class="row">
      <fg-input
        class="col-sm-9"
        label="Staff member"
        type="email"
        name="name"
        v-model="member">
      </fg-input>
      <p-button v-on:click="addMember" class="form-group" type="primary" style="height: fit-content; margin-top: auto;">
        Add member
      </p-button>
    </div>
    <div>
      <el-table
        :data="staff_members"
        class="table-striped"
        border
        style="width: 100%">
        <el-table-column label="logo" width="80px">
                      <span slot-scope="scope">
                        <img :src="scope.row.avatar" alt="Circle Image"
                             class="img-circle img-no-padding img-responsive">
                      </span>
        </el-table-column>
        <el-table-column label="email">
                      <span slot-scope="scope">
                        {{ scope.row.user_id }}
                      </span>
        </el-table-column>
        <el-table-column label="name">
                      <span slot-scope="scope">
                        {{ scope.row.name }}
                      </span>
        </el-table-column>
        <el-table-column label="action" width="80px;">
          <span slot-scope="scope">
            <p-button type="danger" link v-on:click="removeMember(scope.row)">
              <i class="fa fa-trash" style="font-size: x-large;"></i>
            </p-button>
          </span>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Whitelabel-staff",
  props: {
    staff_members: {
      type: Array,
      description: 'Array of members',
      default: []
    }
  },
  data() {
    return {
      member: '',
    }
  },
  methods: {
    addMember() {
      const success = resp => {
        if (resp.success) {
          let user = {
            avatar: resp.data[0].avatar ? resp.data[0].avatar : '/static/img/faces/face-0.jpg',
            user_id: resp.data[0].userid,
            name: resp.data[0].first_name + ' ' + resp.data[0].last_name,
          }
          this.staff_members.push(user)
        } else {
          fail()
        }
      }
      const fail = resp => {
        this.$toast.warning('User not found')
        console.log(resp)
      }

      this.$getUserInfo(this.member).then(success, fail)
    },
    removeMember(row) {
      this.staff_members = this.staff_members.filter(function (obj) {
          return obj.email !== row.email;
        });
    }
  },
  mounted() {
  }

}
</script>

<style scoped>

</style>
