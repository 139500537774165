<template>
  <div class="mt-3 mb-3">
    <div class="text-center">
      <h5>{{ $t('tradertxt') }}</h5>
    </div>
    <div class="col-md-12">
      <div class="row mt-1 mb-1">
        <p-switch v-model="trader_only_positive" type="success"><i class="fa fa-check" slot="on"></i></p-switch>
        Mostrar somente positivas
      </div>
      <div class="row mt-1 mb-1">
        <p-switch v-model="trader_activate_affiliation" type="success"><i class="fa fa-check" slot="on"></i></p-switch>
        Ativar Afiliação
      </div>
      <div class="row mt-1 mb-1">
        <p-switch v-model="trader_payment_hoko" type="success"><i class="fa fa-check" slot="on"></i></p-switch>
        Usar Pagamentos HOKO
      </div>
    </div>
  </div>
</template>

<script>
import PSwitch from "@/components/UIComponents/PSwitch.vue";

export default {
  name: "Whitelabel-trader",
  components: {
    PSwitch,
  },
  props: {
    trader_only_positive: {
      type: Boolean,
      description: '',
      default: false
    },
    trader_activate_affiliation: {
      type: Boolean,
      description: '',
      default: false
    },
    trader_payment_hoko: {
      type: Boolean,
      description: '',
      default: false
    },
  }
}
</script>

<style scoped>

</style>
