<template>
  <div class="col-md-12">
    <h5 class="text-center"> Customize your whitelabel </h5>
    <hr>
    <!--BG Color-->
    <div class="row">
      <div class="text-left mt-3 col-md-6">
        <label>
          {{ $t('backgroundcolod') }}
        </label>
      </div>
      <div class="text-right col-md-6">
        <v-swatches
          v-model="bg_color_login"
          show-fallback
          fallback-input-type="color"
          colors="text-advanced">
          <p-button
            v-model="bg_color_login"
            wide
            slot="trigger"
            style="width: 250px"
            :style="{'background-color': bg_color_login}"
          > Background color
          </p-button>
        </v-swatches>
      </div>
    </div>

    <!--BTN 1-->
    <div class="row">
      <div class="text-left mt-3 col-md-6">
        <label>{{ $t('backgroundbtn1') }}</label>
      </div>
      <div class="text-right col-md-6">
        <v-swatches
          v-model="bg_color_btn1"
          popover-x="left"
          swatches="text-advanced"
          colors="text-advanced">
          <p-button
            v-model="bg_color_btn1"
            wide
            slot="trigger"
            style="width: 250px"
            :style="{'background-color': bg_color_btn1}"
          > Primary button
          </p-button>
        </v-swatches>
      </div>
    </div>

    <!--BTN 2-->
    <div class="row">
      <div class="text-left mt-3 col-md-6">
        <label>{{ $t('backgroundbtn2') }}</label>
      </div>
      <div class="text-right col-md-6">
        <v-swatches
          v-model="bg_color_btn2"
          popover-x="left"
          swatches="text-advanced"
          colors="text-advanced">
          <p-button
            v-model="bg_color_btn2"
            slot="trigger"
            wide
            style="width: 250px"
            :style="{'background-color': bg_color_btn2}"
          > Secundary button
          </p-button>
        </v-swatches>
      </div>
    </div>


    <div class="row">
      <fg-input
        label="Whitelabel fullname"
        class="col-md-12"
        maxlength="250"
        type="text"
        v-model="fullname">
      </fg-input>
    </div>

    <div class="row">
      <fg-input
        label="Menu name"
        class="col-md-12"
        maxlength="250"
        type="text"
        v-model="menu_name">
      </fg-input>
    </div>

    <!-- BG URL   -->
    <div class="row">
      <fg-input
        :label="$t('backgroundimg')"
        class="col-md-12"
        maxlength="250"
        type="text"
        v-model="bg_img_login">
      </fg-input>
    </div>

    <!--LOGOS-->
    <div class="row">
      <div class="col-md-6">
        <h5>{{ $t('logo') }}</h5>
        <div class="img-div text-center">
          <vue-dropzone
            id="logoLogin"
            :options="dropOptions"
            @vdropzone-file-added="onLogoLoginAdded"
            @vdropzone-complete="onLogoLoginUploaded"
            @vdropzone-max-files-exceeded="onLogoLoginRetry"
            ref="dropzoneLogoLogin"
          >
          </vue-dropzone>
        </div>
      </div>
      <div class="col-md-6">
        <h5>{{ $t('sidebarLogo') }}</h5>
        <div class="img-div text-center">
          <vue-dropzone
            id="logoMenu"
            :options="dropOptions"
            @vdropzone-file-added="onLogoMenuAdded"
            @vdropzone-complete="onLogoMenuUploaded"
            @vdropzone-max-files-exceeded="onLogoMenuRetry"
            ref="dropzoneLogoMenu"
          ></vue-dropzone>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Tooltip} from "element-ui";
import VSwatches from "vue-swatches"
import "vue-swatches/dist/vue-swatches.min.css"
import vueDropzone from "vue2-dropzone";
import VueCookies from "vue-cookies";
import CONSTANTS from "@/assets/constants";

const upload_url = CONSTANTS.methods.mainapiurl() + "/upwhitelabel/";
const auth_headers = {
  'Authorization': 'Bearer ' + VueCookies.get('dash-at'),
  'idtoken': VueCookies.get('dash-it')
};
export default {
  name: "Whitelabel-customization",
  components: {
    [Tooltip.name]: Tooltip,
    vueDropzone,
    VSwatches,
  },
  props: {
    bg_color_login: {
      type: String,
      description: '',
      default: '#51bcda'
    },
    bg_color_btn1: {
      type: String,
      description: '',
      default: '#007bff'
    },
    bg_color_btn2: {
      type: String,
      description: '',
      default: '#6bd098'
    },
    fullname: {
      type: String,
      description: '',
      default: ''
    },
    menu_name: {
      type: String,
      description: '',
      default: ''
    },
    bg_img_login: {
      type: String,
      description: '',
      default: ''
    },
    logo: {
      type: String,
      description: '',
      default: ''
    },
    sidebar_logo: {
      type: String,
      description: '',
      default: ''
    },
  },
  data() {
    return {
      disable_initials: false,
      modelValidations: {
        whitelabel: {required: true, max: 10},
        email: {required: false, email: true, max: 100},
        fullname: {required: true, max: 50},
        wlmenuname: {required: true, max: 50},
        provision_url: {required: true, max: 250},
        vps_prefix: {required: true, max: 3},
        bgcolor_login: {required: true, max: 7, min: 7}
      },
      dropOptions: {
        headers: auth_headers,
        url: upload_url,
        maxFilesize: 1,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: true,
        acceptedFiles: "image/png,image/jpg,image/jpeg,image/gif",
        dictDefaultMessage: "<i class='nc-icon nc-cloud-upload-94' style='font-size: 35px;'></i>" + this.$t('uploadtxt'),
      },
    }
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    onLogoLoginAdded(file) {
      this.onLogoAdded(file, 'login', 'dropzoneLogoLogin');
    },
    onLogoLoginUploaded(file) {
      if (file.status === 'error') {
        this.$toast.warning(this.$t('ERROR_UNEXPECTED'));
        return;
      }
      const response = JSON.parse(file.xhr.response);
      this.logo = response.data.logo_name;
      this.updateDropzoneImage(file, this.logo);
    },
    onLogoMenuAdded(file) {
      this.onLogoAdded(file, 'menu', 'dropzoneLogoMenu');
    },
    onLogoLoginRetry(file) {
      const self = this;
      new Promise(function (resolve) {
        self.whitelabel.logo_login = '';
        resolve();
      }).then(function () {
        self.onUploadRetry(file, 'dropzoneLogoLogin');
      })
    },
    onLogoMenuUploaded(file) {
      if (file.status === 'error') {
        this.$toast.warning(this.$t('ERROR_UNEXPECTED'));
        return;
      }
      const response = JSON.parse(file.xhr.response);
      this.sidebar_logo = response.data.logo_name;
      this.updateDropzoneImage(file, this.sidebar_logo);
    },
    onLogoMenuRetry(file) {
      this.onUploadRetry(file, 'dropzoneLogoMenu');
    },
  },
  mounted() {
  },
}
</script>

<style scoped>

</style>
