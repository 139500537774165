<template>
  <div>
    <!--    <div class="header">-->
    <!--     -->
    <!--    </div>-->
    <div class="col-md-12">
      <div class="mt-3">
        <div v-if="!iamBroker">
          <h6 class="text-center">Select who your are as a broker</h6>
        </div>
        <div v-if="iamBroker">
          <div>
            <div class="col-md-12 broker-logo logo-size-1 text-center">
              <h5 >Configuring whitelabel for broker</h5>
              <b><img :src="url_img + iamBroker.logo + '.png'"> {{
                  iamBroker.name
                }}</b>
              <hr>
            </div>

          </div>
          <h6 class="text-center" v-if="iamBroker">Now Select brokers listed in your whitelabel</h6>
        </div>
      </div>
      <label style="font-weight: bold">Filter</label>
      <fg-input
        type="text"
        name="name"
        addon-left-icon="fa fa-search"
        v-model="searchQuery">
      </fg-input>
    </div>
    <div v-if="brokerList.length <= 0" class="col-md-12 text-center">
      <h2>{{ $t('loadingtxt') }}</h2>
      <i class="fa fa-cog fa-spin fa-huge fa-fw col-6" style="color: #51bcda;"></i>
    </div>
    <div v-else class="row">
      <el-container>
        <el-main>
          <data-tables-server
            v-if="!iamBroker"
            ref="datatable"
            v-loading="false"
            :total="total"
            :data="queriedData"
            :table-props="configTable"
            @current-change="onSelect"
          >
            <div slot="empty">{{ $t('norecordsfounded') }}</div>
            <span slot="pagination" class="el-pagination__total ml-2">{{ $t('total_records_found', [total]) }}</span>
            <el-table-column label="Broker">
              <template slot-scope="props">
                <div class="broker-logo logo-size-1">
                  <img :src="url_img + props.row.logo + '.png'">
                  {{ props.row.name }}
                </div>
              </template>
            </el-table-column>
          </data-tables-server>
          <data-tables-server
            v-else
            ref="datatable"
            v-loading="false"
            :total="total"
            :data="queriedData"
            :table-props="configTable"
          >
            <div slot="empty">{{ $t('norecordsfounded') }}</div>
            <span slot="pagination" class="el-pagination__total ml-2">{{ $t('total_records_found', [total]) }}</span>
            <el-table-column label="Included?" width="110px" v-if="iamBroker">
              <template slot-scope="props">
                <p-switch @input="onSelectBroker(props.row)" type="success" v-model="props.row.active">
                  <i class="fa fa-check" slot="on"></i>
                </p-switch>
              </template>
            </el-table-column>
            <el-table-column label="Broker" width="280px">
              <template slot-scope="props">
                <div class="broker-logo logo-size-1">
                  <img :src="url_img + props.row.logo + '.png'">
                  {{ props.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="URL" v-if="iamBroker">
              <template slot-scope="props">
                <fg-input v-on:change="updateBrokerUrl(props.row)" v-model="props.row.url" :disabled="!props.row.active"
                          placeholder="Custom URL (optional)"></fg-input>
              </template>
            </el-table-column>
          </data-tables-server>
        </el-main>
      </el-container>
    </div>
    <hr>
    <div class="mt-3 mb-3">
      <h5> Settings for Broker </h5>
      <div class="mt-3 mb-3">
        <div class="mt-1 mb-1">
          <p-switch type="success" v-model="broker_automation">
            <i class="fa fa-check" slot="on"></i>
          </p-switch>
          Automação
        </div>
        <div class="mt-1 mb-1">
          <p-switch type="success" v-model="broker_strategy">
            <i class="fa fa-check" slot="on"></i>
          </p-switch>
          Estratégia Própria
        </div>
        <div class="mt-1 mb-1">
          <p-switch type="success" v-model="broker_vps">
            <i class="fa fa-check" slot="on"></i>
          </p-switch>
          VPS
        </div>
        <div class="mt-2 mb-2">
          <fg-input label="Vps prefix" v-model="vps_prefix_vps"></fg-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Select, Option} from 'element-ui'
import PSwitch from "@/components/UIComponents/PSwitch";
import WhitelabelBrokerUrl from "@/components/Dashboard/Views/Dashboard/Whitelabel/WhitelabelBrokerUrl.vue";
import swal from "sweetalert2";


export default {
  name: "whitelabel-broker.vue",
  components: {
    WhitelabelBrokerUrl,
    [Select.name]: Select,
    [Option.name]: Option,
    PSwitch
  },
  props: {
    broker_automation: {
      type: Boolean,
      description: 'Configuration Parameter',
      default: false
    },
    broker_strategy: {
      type: Boolean,
      description: 'Configuration Parameter',
      default: false
    },
    broker_vps: {
      type: Boolean,
      description: 'Configuration Parameter',
      default: false
    },
    vps_prefix_vps:  {
      type: String,
      description: 'Vps prefix',
      default: ''
    },
    broker_id: {
      type: Number,
      description: 'Id of broker',
    },
    brokers: {
      type: Array,
      description: 'List of brokers listed in whitelabel'
    }

  },
  computed: {
    pagedData() {
      return this.brokerList.slice(this.from, this.to)
    },
    configTable() {
      return {
        height: document.querySelector('.content').clientHeight,
        highlightCurrentRow: true,
        stripe: true,
      };
    },
    queriedData() {
      if (!this.searchQuery) {
        this.pagination.total = this.brokerList.length
        return this.pagedData
      }
      let result = this.brokerList
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            let searchRegex = new RegExp(this.searchQuery, 'gi');
            if (row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      this.pagination.total = this.brokerList.length
      return this.brokerList.length
    }
  },
  data() {
    return {
      url_img: "https://hoko.ams3.digitaloceanspaces.com/brokerlogos/",
      brokerList: [],
      selectedBrokers: [],
      iamBroker: 0,
      searchQuery: "",
      propsToSearch: ['name'],
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
    }
  },
  methods: {
    onSelect(rowData) {
      swal({
        title: this.$t('needconfirmation'),
        text: "Você está selecionado a corretora de sua whitelabel, tem certeza desta operação?",
        type: 'warning',
        showCancelButton: true,
      }).then(() => {
        this.iamBroker = rowData;
        this.broker_id = this.iamBroker.id
        this.brokerList = this.brokerList.filter(function (obj) {
          return obj.id !== rowData.id;
        });
      })
        .catch(swal.noop);
    },
    loadBrokers(resp) {
      let temp = resp.data.map(v => ({...v, active: false}))
      this.brokerList = temp
    },
    onSelectBroker(row) {
      this.brokers.push(row)
    },
    updateBrokerUrl(e) {
      console.log(e)
    }
  },
  mounted() {
    this.$listBrokers().then(this.loadBrokers, this.failBrokers);
  }
}
</script>

<style scoped lang="scss">
.avatar-broker {
  margin-top: 25px;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.fa fa-check:hover {
  background-color: orangered;
}

.scroll-brokers {
  height: 660px;
  overflow: scroll;
  overflow-x: hidden;
}
</style>
