<template>
  <div v-if="inprogress" class="text-center">
    <h2>{{ $t("loadingtxt") }}</h2>
    <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda"></i>
    <p></p>
  </div>
  <form v-else>
    <div class="row">
      <div class="col-6">
        <div class="mt-3">
          <div class="mb-4">
            <fg-input
                label="Whitelabel name"
                type="text"
                name="name"
                v-model="whitelabel.whitelabel"
                maxlength="10">
            </fg-input>
            <fg-input
                label="Administrator email"
                type="email"
                name="Email"
                maxlength="100"
                v-model="whitelabel.admin_email"
                @change="check_email">
            </fg-input>

            <fg-input
                label="Domain"
                type="email"
                name="Email"
                maxlength="100"
                v-model="whitelabel.domain"
                @change="check_email">
            </fg-input>

            <div class="row">
              <label class="col-md-12">
                Plan
              </label>

              <el-select v-model="whitelabel.type" @change="typeChanged" class="col-md-12">
                <el-option class="select-default"
                           v-for="item in whitelabelPlanTypeOptions"
                           :key="item.name" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>

          </div>
          <collapse :multiple-active="true" :active-index="0">
            <collapse-item :title="configurationColapseItem" name="1">
              <whitelabel-broker v-if="selected_plan.type =='broker'"
                                 :broker_id="broker_id"
                                 :brokers="brokers"
                                 :vps_prefix_vps="vps_prefix_vps"
                                 :broker_automation="broker_automation"
                                 :broker_strategy="broker_strategy"
                                 :broker_vps="broker_vps"
              ></whitelabel-broker>
              <whitelabel-affiliate v-if="selected_plan.type =='affiliate'"
                                    :affiliate_activate="affiliate_activate"
                                    :affiliate_payment_hoko="affiliate_payment_hoko"
                                    :affiliate_strategy_marketplace="affiliate_strategy_marketplace"/>
              <whitelabel-trader v-if="selected_plan.type =='trader'"
                                 :trader_only_positive="trader_only_positive"
                                 :trader_activate_affiliation="trader_activate_affiliation"
                                 :trader_payment_hoko="trader_payment_hoko"/>
            </collapse-item>
            <collapse-item title="Customize" name="2">
              <whitelabel-customization
                  :bg_color_login="bg_color_login"
                  :bg_color_btn1="bg_color_btn1"
                  :bg_color_btn2="bg_color_btn2"
                  :fullname="fullname"
                  :menu_name="menu_name"
                  :bg_img_login="bg_img_login"
                  :logo="logo"
                  :sidebar_logo="sidebar_logo">
              </whitelabel-customization>
            </collapse-item>
            <collapse-item title="Staff" name="3">
              <whitelabel-staff></whitelabel-staff>
            </collapse-item>
          </collapse>
        </div>
      </div>
      <div class="col-6">
        <div class="mt-3">
          selected plan
          <p><b>{{ selected_plan.name}}:</b> {{ selected_plan.type }}</p>
          <hr>
          Options:
          <p v-for="item in whitelabelPlanTypeOptions">{{item.name}}: {{item.type}}</p>
          <hr>
          WL type: {{whitelabel}}
          <hr>
          Computed type: {{getTypeOfPlan}}
          <whitelabel-info :wlPlanType="selected_plan.type"></whitelabel-info>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import whitelabelCrud from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/WhitelabelCrud";
import {Select} from 'element-ui'
import {Collapse, CollapseItem} from 'src/components/UIComponents'
import Vue from "vue";
import WhitelabelBroker from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-broker.vue";
import PSwitch from "../../../../UIComponents/PSwitch";
import WhitelabelAffiliate from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-affiliate.vue";
import WhitelabelTrader from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-trader.vue";
import WhitelabelCustomization from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-customization.vue";
import WhitelabelStaff from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-staff.vue";
import WhitelabelInfo from "@/components/Dashboard/Views/Dashboard/Whitelabel_v2/Whitelabel-info.vue";

Vue.use(Select)
export default {
  name: "WhitelabelForm",
  components: {
    WhitelabelCustomization,
    whitelabelCrud,
    WhitelabelBroker,
    WhitelabelAffiliate,
    WhitelabelTrader,
    WhitelabelStaff,
    WhitelabelInfo,
    PSwitch,
    CollapseItem,
    Collapse,
  },
  data() {
    return {
      whitelabelPlanTypeOptions: [],
      inprogress: true,
      url_img: "https://hoko.ams3.digitaloceanspaces.com/brokerlogos/",
      brokerList: [],
      selected_plan: {
        name: '',
        type: '',
        parameters: []
      },
      whitelabel: {
        whitelabel: "",
        full_name: "",
        admin_email: "",
        type: '',
        brokers: [],
        strategies: [],
        staff: [],
        parameters: []
      },
      trader_only_positive: false,
      trader_activate_affiliation: false,
      trader_payment_hoko: false,
      affiliate_activate: false,
      affiliate_payment_hoko: false,
      affiliate_strategy_marketplace: false,
      broker_id: null,
      brokers: [],
      vps_prefix_vps: '',
      broker_automation: false,
      broker_strategy: false,
      broker_vps: false,
      staff_members: [],
      bg_color_login: '',
      bg_color_btn1: '',
      bg_color_btn2: '',
      fullname: '',
      menu_name: '',
      bg_img_login: '',
      logo: '',
      sidebar_logo: '',
    }
  },
  computed: {
    configurationColapseItem() {
      return this.whitelabel.type.charAt(0).toUpperCase() + this.whitelabel.type.slice(1) + " Configuration"
    },
    getTypeOfPlan() {
      if(this.whitelabel.type) {
        let param = this.whitelabelPlanTypeOptions.findIndex( (el) => el.name == this.whitelabel.type)
        return param.type
      }
      else
        return ''
    },
    captilizeWord(staticWord, word) {
      console.log(staticWord)
      console.log(word)
      return staticWord +' - '+ word[0].toUpperCase() + word.slice(1).toLowerCase();
    },
  },
  methods: {
    editForm(whitelabel_info) {
      this.isEditing = true
      this.whitelabel.whitelabel = whitelabel_info.whitelabel
      this.whitelabel.admin_email = whitelabel_info.admin_email
      this.whitelabel.type = whitelabel_info.type
      this.whitelabel.brokers = whitelabel_info.brokers
      this.whitelabel.strategies = whitelabel_info.strategies
      this.whitelabel.staff = whitelabel_info.staff
      this.whitelabel.parameters = whitelabel_info.parameters
    },
    showHideInprogress() {
      this.inprogress = !this.inprogress;
    },
    failBrokers(val) {
      console.log(val);
    },
    loadBrokers(resp) {
      this.brokerList = resp.data.slice(0);
    },
    check_email() {
      this.$check_wl_email({
        email: this.whitelabel.admin_email,
        whitelabel: this.whitelabel.whitelabel
      }).then((resp) => {
        console.log(resp)
      })
    },
    typeChanged() {
      let param = this.whitelabelPlanTypeOptions.find(el => el.name == this.whitelabel.type)
      this.selected_plan = param
    },
    submit() {
      // if (this.whitelabel.type == 'broker') {
        // this.whitelabel.broker_id = this.broker_id
        // this.whitelabel.brokers = this.brokers
      // }

       let default_parameters= [
        {parameter_name: 'FULLNAME', parameter_value: this.fullname},
        {parameter_name: 'MENU_NAME', parameter_value: this.menu_name},
        {parameter_name: 'LOGO_LOGIN', parameter_value: this.logo},
        {parameter_name: 'BROKER_AUTOMATION', parameter_value: this.broker_automation},
        {parameter_name: 'BROKER_STRATEGY', parameter_value: this.broker_strategy},
        {parameter_name: 'BROKER_VPS', parameter_value: this.broker_vps},
        {parameter_name: 'VPS_PREFIX', parameter_value: this.vps_prefix_vps},
        {parameter_name: 'TRADER_ONLY_POSITIVE', parameter_value: this.trader_only_positive},
        {parameter_name: 'TRADER_ACTIVATE_AFFILIATION', parameter_value: this.trader_activate_affiliation},
        {parameter_name: 'TRADER_PAYMENT_HOKO', parameter_value: this.trader_payment_hoko},
        {parameter_name: 'AFFILIATE_ACTIVATE', parameter_value: this.affiliate_activate},
        {parameter_name: 'AFFILIATE_PAYMENT_HOKO', parameter_value: this.affiliate_payment_hoko},
        {parameter_name: 'AFFILIATE_STRATEGY_MARKETPLACE', parameter_value: this.affiliate_strategy_marketplace},
        {parameter_name: 'BG_COLOR_LOGIN', parameter_value: this.bg_color_login},
        {parameter_name: 'BG_COLOR_BTN1', parameter_value: this.bg_color_btn1},
        {parameter_name: 'BG_COLOR_BTN2', parameter_value: this.bg_color_btn2},
        {parameter_name: 'BG_IMG_LOGIN', parameter_value: this.bg_img_login},
        {parameter_name: 'SIDEBAR_LOGO', parameter_value: this.sidebar_logo}
      ]
      // TODO: checar origem parametros
      this.whitelabel.parameters = [].concat(this.selected_plan.parameters, default_parameters)

      this.whitelabel.staff = this.staff_members

      const success = (resp) => {
        console.log(resp)
      }
      const failed = (resp) => {
        console.log(resp)
      }

      if (!this.isEditing) {
        this.$createWhitelabel_v2(this.whitelabel).then(success, failed)
      } else {
        this.$updateWhitelabel_v2(this.whitelabel).then(success, failed)
      }
    }
  },
  mounted() {

    let success = resp => {
      this.whitelabelPlanTypeOptions = resp.data
      this.showHideInprogress();
    }

    let fail = resp => {
      console.log(resp)
      this.showHideInprogress();
    }

    this.$getWhitelabelPLanList().then(success, fail)

  }
}
</script>

<style scoped lang="scss">

</style>
